<template>
  <VCard
    :loading="loading"
    :disabled="loading"
  >
    <VCardTitle>
      Редактирование пользователя
    </VCardTitle>

    <VCardText>
      <VForm>
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.firstName"
                label="Имя"
                name="firstName"
                persistent-hint
                hint="Имя пользователя"
                @input="$emit('update:firstName', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.lastName"
                label="Фамилия"
                name="lastName"
                persistent-hint
                hint="Фамилия пользователя"
                @input="$emit('update:lastName', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.middleName"
                label="Отчество"
                name="middleName"
                persistent-hint
                hint="Отчество пользователя"
                @input="$emit('update:middleName', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VAutocomplete
                :value="entity.sex"
                :items="genders"
                :filter="customFilter"
                item-text="name"
                item-value="value"
                label="Пол"
                name="sex"
                persistent-hint
                hint="Пол пользователя"
                @input="$emit('update:sex', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.dateOfBirth"
                label="Дата рождения"
                name="dateOfBirth"
                persistent-hint
                hint="Дата рождения пользователя"
                @input="$emit('update:dateOfBirth', $event)"
              />
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        @click="handleSubmit"
      >
        Сохранить
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
export default {
  name: 'UserFormEdit',

  props: {
    entity: {
      type: Object,
      default: () => ({}),
    },
    genders: {
      type: Array,
      default: () => ([]),
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    customFilter(item, queryText) {
      const name = item.name.toLowerCase();
      const value = item.value.toLowerCase();
      const searchText = queryText.toLowerCase();

      return name.indexOf(searchText) > -1 || value.indexOf(searchText) > -1;
    },
    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      this.$emit('submit', this.entity);
    },
  },
};
</script>
