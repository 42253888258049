<template>
  <TTView>
    <VRow>
      <VCol>
        <UserFormEdit
          :entity="user"
          :loading="loading"
          :genders="genders"
          @update:firstName="user.firstName = $event"
          @update:lastName="user.lastName = $event"
          @update:middleName="user.middleName = $event"
          @update:dateOfBirth="user.dateOfBirth = $event"
          @update:sex="user.sex = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import UserFormEdit from '../../components/authentication/UserFormEdit.vue';

export default {
  name: 'UserEdit',

  components: {
    UserFormEdit,
  },

  data() {
    return {
      loading: false,
      genders: [
        {
          value: 'SEX_MALE',
          name: 'Мужчина',
        },
        {
          value: 'SEX_FEMALE',
          name: 'Женщина',
        },
      ],
      user: {
        active: false,
        accounts: [
          {
            login: '',
            type: '',
            main: false,
          },
        ],
        firstName: '',
        lastName: '',
        middleName: '',
        dateOfBirth: '',
        sex: '',
      },
    };
  },

  computed: {
    userId() {
      return this.$route.params.userId;
    },
  },
  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const data = { id: this.userId };
        const user = await this.$di.api.Authentication.getUser(data);

        this.user = user;
      } catch (err) {
        this.$di.notify.errorHandler(err);
        this.$di.redirect.errorHandler404(err);
      } finally {
        this.loading = false;
      }
    },

    async handleSubmit() {
      try {
        this.loading = true;
        const data = {
          id: this.userId,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          middleName: this.user.middleName,
          dateOfBirth: this.user.dateOfBirth,
          sex: this.user.sex,
          updateFields: [
            1, 2, 3, 4, 5,
          ],
        };

        await this.$di.api.Authentication.userUpdate({ data });

        this.$router.go(-1);
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
